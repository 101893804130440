import React, { useEffect, useMemo, useState } from 'react';
import {
  DeleteButton,
  ShowButton,
  RaRecord,
  ReferenceField,
  TextField,
  Labeled,
  DateField,
  Button,
  useRefresh,
  useNotify,
  useUpdate,
  BooleanField,
} from 'react-admin';
import OsDisplay from '../../fields/OsDisplay';
import BrowserDisplay from '../../fields/BrowserDisplay';
import CountryDisplay from '../../fields/CountryDisplay';
import QuickEditTake from './QuickEditTake';
import makeStyles from '@mui/styles/makeStyles';
import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { formatDateTimeEnGB } from '../../utils/general';
import { Link } from 'react-router-dom';
import { intervalToDuration } from 'date-fns';
import {
  Preview,
  VideoCameraFront,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { useUpdateShow } from './hooks/useUpdateShow';
import useDownloadWithStream from '../../hooks/useDownloadWithStream';
import VideoSessionIncentivesList from './VideoSessionIncentivesList';
import { BrandVettingMethod } from '../../types';

const ALARM_THRESHOLD = 16,
  WARNING_THRESHOLD = 8;
const useStyles = makeStyles(() => ({
  label: { display: 'flex', flexDirection: 'row' },
  showContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  showLeft: {
    width: '80%',
  },
  showRight: {
    textAlign: 'right',
  },
}));
interface SessionFooterProps {
  record: RaRecord & {
    creator: { fullname: string; email: string; phone: string } | null;
  };
  consentGiven: boolean;
  isQualified: boolean | null;
  currentTakeIndex: number;
}

enum SubmissionColors {
  Red = '#B81D13',
  Yellow = '#EFB700',
  Green = '#008450',
}

const SessionFooter: React.FC<SessionFooterProps> = ({
  record,
  currentTakeIndex,
}: any) => {
  const [markedSubmitDate, setMarkedSubmitDate] = useState(
    intervalToDuration({ start: new Date(record.createdAt), end: new Date() }),
  );

  const classes = useStyles();

  const moreThanHours =
    markedSubmitDate?.days !== 0 ||
    markedSubmitDate?.months !== 0 ||
    markedSubmitDate?.years !== 0;

  const submitDateColor = useMemo(() => {
    if (markedSubmitDate?.hours && markedSubmitDate?.hours !== 0) {
      return moreThanHours || markedSubmitDate.hours > ALARM_THRESHOLD
        ? SubmissionColors.Red
        : markedSubmitDate.hours > WARNING_THRESHOLD
        ? SubmissionColors.Yellow
        : SubmissionColors.Green;
    } else {
      return SubmissionColors.Green;
    }
  }, [markedSubmitDate.hours, moreThanHours]);

  const submitDateDisplay = () => {
    const {
      hours = 0,
      minutes = 0,
      days = 0,
      months = 0,
      years = 0,
    } = markedSubmitDate;

    if (years) {
      return `over a year ago`;
    }

    if (months || days) {
      const monthsText = months ? `${months}m` : '';
      const daysText = days ? `${days}d` : '';
      return `${monthsText}${months && days ? ', ' : ''}${daysText} ago`;
    }

    return `${hours}:${String(minutes).padStart(2, '0')}h ago`;
  };

  useEffect(() => {
    if (!moreThanHours) {
      const interval = setInterval(
        () =>
          setMarkedSubmitDate(
            intervalToDuration({
              start: new Date(record.createdAt),
              end: new Date(),
            }),
          ),
        60000,
      );

      return () => clearInterval(interval);
    } else {
      setMarkedSubmitDate(
        intervalToDuration({
          start: new Date(record.createdAt),
          end: new Date(),
        }),
      );
    }
  }, [moreThanHours, record.createdAt]);

  const primaryTakes = useMemo(() => {
    return record.videoScenes.map((scene: any) => {
      return scene.takes.reduce((acc: any, curr: any) => {
        return new Date(acc.createdAt).getTime() >
          new Date(curr.createdAt).getTime()
          ? acc
          : curr;
      });
    });
  }, [record]);

  const refresh = useRefresh();
  const notify = useNotify();
  const { downloadVideoSessionsWithStream } = useDownloadWithStream();

  const [updateVetAll, { isLoading: isVetAllLoading }] = useUpdate(
    `videosessions`,
    {
      id: `${record.id}/takes/status`,
      data: { status: 'VETTED' },
    },
    {
      onSuccess: data =>
        notify(
          `${data.vetCount} ${data.vetCount > 1 ? 'scenes' : 'scene'} vetted`,
          { type: 'success' },
        ),
      onError: () =>
        notify(`Failed to vet all scenes in video session`, { type: 'error' }),
      onSettled: () => refresh(),
    },
  );

  const [updateRejectAllFraud, { isLoading: isRejectAllFraudLoading }] =
    useUpdate(
      `videosessions`,
      {
        id: `${record.id}/takes/status`,
        data: { status: 'REJECTED', statusReason: 'FRAUD' },
      },
      {
        onSuccess: data =>
          notify(
            `${data.vetCount} ${
              data.vetCount > 1 ? 'scenes' : 'scene'
            } rejected`,
            { type: 'success' },
          ),
        onError: () =>
          notify(`Failed to reject all scenes in video session`, {
            type: 'error',
          }),
        onSettled: () => refresh(),
      },
    );

  const { updateVideoSession: updateShowSession, resourceData } = useUpdateShow(
    {
      isHidden: record?.isHidden,
      id: record?.id,
      resource: 'videosessions',
      entityName: 'Video session',
    },
  );

  const shownInDashboard = resourceData
    ? !resourceData.isHidden
    : !record.isHidden;

  const isVettedByBrand =
    record.campaign.brand?.vettingMethod === BrandVettingMethod.BRAND;

  return (
    <div>
      <div className={classes.showContainer}>
        <div className={classes.showLeft}>
          <Labeled label="Question:" className={classes.label}>
            <TextField
              source="question"
              record={primaryTakes[currentTakeIndex]}
              emptyText="N/A"
            />
          </Labeled>
          <Labeled label="Id: " className={classes.label}>
            <TextField source="id" record={record} emptyText="N/A" />
          </Labeled>
          <Labeled label="Consent: " className={classes.label}>
            <BooleanField
              record={record}
              source="consentGiven"
              label="Consent"
            />
          </Labeled>

          <Labeled label="Brand:" className={classes.label}>
            <ReferenceField
              source="campaign.brand.id"
              reference="brands"
              emptyText="N/A"
              record={record}
            >
              <TextField source="name" style={{ textOverflow: 'clip' }} />
            </ReferenceField>
          </Labeled>

          <Labeled label="Campaign:" className={classes.label}>
            <ReferenceField
              source="campaignId"
              reference="campaigns"
              record={record}
            >
              <TextField source="title" style={{ textOverflow: 'clip' }} />
            </ReferenceField>
          </Labeled>
          <Labeled label="Creator:" className={classes.label}>
            <ReferenceField
              source="creatorId"
              reference="creators"
              record={record}
              emptyText="N/A"
              link="show"
            >
              <TextField source="email" />
              {record.creator && (
                <Typography
                  variant="caption"
                  style={{ color: 'red', marginLeft: '2%' }}
                >
                  ({record.creator?.submissionCount})
                </Typography>
              )}
            </ReferenceField>
          </Labeled>
          <div
            className={classes.label}
            style={{
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '5px',
            }}
          >
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '0.75rem',
              }}
            >
              Visibility:&nbsp;&nbsp;
            </span>
            <span style={{ fontSize: '0.75rem' }}>
              {shownInDashboard ? 'Shown' : 'Hidden'}
            </span>
          </div>
          <div
            className={classes.label}
            style={{
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '5px',
            }}
          >
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '0.75rem',
              }}
            >
              Video Ads:
            </span>
            {record.videoAds?.map((videoAd: any) => (
              <Grid item ml={'5px'}>
                <Link to={`/videoads/${videoAd.id}/show`}>
                  <Chip
                    icon={<VideoCameraFront />}
                    size="small"
                    label={videoAd.id}
                    clickable
                  />
                </Link>
              </Grid>
            ))}
          </div>
          <div
            className={classes.label}
            style={{
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: '5px',
            }}
          >
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '0.75rem',
                marginBottom: '5px',
              }}
            >
              Incentives:
            </span>
            <div>
              <VideoSessionIncentivesList
                incentives={record?.videoScenes
                  ?.flatMap((scene: any) => scene?.incentives)
                  .filter(Boolean)}
              />
            </div>
          </div>
        </div>
        <div className={classes.showRight}>
          <DateField source="createdAt" record={record} locales="en-GB" />
          <Typography
            variant="caption"
            style={{
              padding: '2px 4px',
              backgroundColor: submitDateColor,
              display: 'inline-block',
              fontWeight: 'bold',
            }}
          >
            {submitDateDisplay()}
          </Typography>
          <div>
            <OsDisplay osName={record.userOSName} />
            <BrowserDisplay browserName={record.userClientName} />
            <CountryDisplay userCountryCode={record?.userCountryCode} />
          </div>
          <div>
            {record.retakeOfId && (
              <Tooltip title="Retake">
                <span>🔁</span>
              </Tooltip>
            )}
            {record.retakeEmailSentAt && (
              <Tooltip
                title={`Retake Email Sent At: ${formatDateTimeEnGB(
                  new Date(record.retakeEmailSentAt),
                )}`}
              >
                <span>📤</span>
              </Tooltip>
            )}
            {record.reminderEmailSentAt && (
              <Tooltip
                title={`Reminder Email Sent At: ${formatDateTimeEnGB(
                  new Date(record.reminderEmailSentAt),
                )}`}
              >
                <span>📤</span>
              </Tooltip>
            )}
            {record.rejectEmailSentAt && (
              <Tooltip
                title={`Reject Email Sent At: ${formatDateTimeEnGB(
                  new Date(record.rejectEmailSentAt),
                )}`}
              >
                <span>🙅‍♂️</span>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.125rem' }}>
        <ShowButton label="VIEW" record={record} startIcon={<Preview />} />
        <DeleteButton record={record} mutationMode="pessimistic" />
        <Button
          label="Download"
          onClick={() => downloadVideoSessionsWithStream([record])}
        >
          <DownloadIcon />
        </Button>
        {record.videoAds > 0 && (
          <Link
            to={`/videoads?filter=${encodeURIComponent(
              `{"videoSessionId":${record.id}}`,
            )}`}
          >
            <Button label={`X${record.videoAds.length}`}>
              <OndemandVideoIcon />
            </Button>
          </Link>
        )}
        <Button
          startIcon={<DoneAllIcon />}
          disabled={
            isVettedByBrand ||
            isVetAllLoading ||
            !primaryTakes?.some((take: any) => take.status !== 'VETTED')
          }
          onClick={() => updateVetAll()}
          style={{ marginLeft: '5px' }}
          color="primary"
          label="Vet All"
        />
        <Button
          startIcon={<DoDisturbOnIcon />}
          disabled={
            isVettedByBrand ||
            isRejectAllFraudLoading ||
            !primaryTakes?.some((take: any) => take.status !== 'REJECTED')
          }
          onClick={() => updateRejectAllFraud()}
          style={{ marginLeft: '5px' }}
          color="primary"
          label="Reject All (Fraud)"
        />
        <Button
          color="primary"
          startIcon={!shownInDashboard ? <Visibility /> : <VisibilityOff />}
          label={!shownInDashboard ? 'Show' : 'Hide'}
          onClick={() => updateShowSession()}
        />
      </div>
      {primaryTakes.map((t: any) => (
        <QuickEditTake
          key={t.id}
          record={t}
          showEmoji
          disableTakeVetting={isVettedByBrand}
        />
      ))}
    </div>
  );
};

export default SessionFooter;
