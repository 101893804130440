import { VideoCameraFront } from '@mui/icons-material';
import { Box, Chip, Grid } from '@mui/material';
import { Button, Link, useUpdate } from 'react-admin';
import {
  AdRequest,
  AdRequestEditStatus,
  Guideline,
  GuidelineMapping,
} from '../../types';
import { useMemo } from 'react';

type AdRequestEntity = {
  id?: string;
  name?: string;
  title?: string;
  url?: string;
};

const baseStyle = {
  marginRight: '5px',
  marginBottom: '5px',
};

export const AdRequestPanel = ({ record }: { record: AdRequest }) => {
  const {
    videoSession,
    videoAd,
    asset,
    numOfHooks,
    adObjective,
    tones,
    editingStyle,
    platforms,
    sizes,
    guidelines,
  } = record;

  const entityData = useMemo((): AdRequestEntity | null => {
    if (videoSession) {
      return {
        id: videoSession.id,
        title: 'Video Session',
        name: videoSession.name,
        url: `/videosessions/${videoSession.id}/show`,
      };
    }

    if (videoAd) {
      return {
        id: videoAd.id,
        title: 'Video Ad',
        name: videoAd.name,
        url: `/videoads/${videoAd.id}/show`,
      };
    }

    if (asset) {
      return {
        id: asset.id,
        title: 'Asset',
        name: asset.name,
        url: `/assets/${asset.id}/show`,
      };
    }
    return null;
  }, [asset, videoAd, videoSession]);

  const [update] = useUpdate('ad-requests', { id: record.id });

  const guidelinesByTypeMap: GuidelineMapping = useMemo(() => {
    return (
      guidelines?.reduce((acc: GuidelineMapping, guideline: Guideline) => {
        if (!acc[guideline.type]?.length) {
          return { ...acc, [guideline.type]: [guideline] };
        }
        return {
          ...acc,
          [guideline.type]: [...(acc[guideline.type] || []), guideline],
        };
      }, {} as GuidelineMapping) || {}
    );
  }, [guidelines]);
  return (
    <Grid container spacing={2} style={{ marginBottom: '1%' }}>
      {!entityData ? (
        <h4>Unknown ad request entity</h4>
      ) : (
        <>
          <Grid container spacing={2} pl={5}>
            <Grid item xs={2}>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                {entityData.title}
              </h4>

              <Link
                key={entityData.id}
                to={entityData.url || ''}
                style={{ marginRight: '20px' }}
              >
                <Chip
                  icon={<VideoCameraFront />}
                  label={entityData.name}
                  clickable
                  style={{ padding: '5px' }}
                />
              </Link>
            </Grid>
            <Grid item xs={1}>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>Editing Style</h4>
              {editingStyle && <Chip label={editingStyle} />}
            </Grid>
            <Grid item xs={1}>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>Ad Objective</h4>
              {adObjective && <Chip label={adObjective} />}
            </Grid>

            <Grid item xs={1}>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}># of Hooks</h4>
              {numOfHooks && <Chip label={numOfHooks} />}
            </Grid>
            <Grid item xs={2}>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>Platforms</h4>
              {platforms?.map(platform => (
                <Chip key={platform} label={platform} style={baseStyle} />
              ))}
            </Grid>
            <Grid item xs={2}>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>Tones</h4>
              {tones?.map(tone => (
                <Chip key={tone} label={tone} style={baseStyle} />
              ))}
            </Grid>
            <Grid item xs={2}>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>Sizes</h4>
              {sizes?.map(size => (
                <Chip key={size} label={size} style={baseStyle} />
              ))}
            </Grid>
          </Grid>
          <Grid container spacing={2} pl={5}>
            <Grid item xs={4}>
              <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>Guidelines</h4>
              <Box display={'flex'} gap={5}>
                {Object.entries(guidelinesByTypeMap).map(([type, value]) => {
                  return (
                    <Box display={'flex'} flexDirection={'column'}>
                      <h4 style={{ color: 'rgba(0, 0, 0, 0.4)' }}>{type}</h4>
                      <Grid item xs={12}>
                        {value.map(({ id, text }) => {
                          return (
                            <Chip
                              key={`request_guideline_${id}}`}
                              label={text}
                              style={{ marginBottom: '10px' }}
                            />
                          );
                        })}
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={5} pl={5}>
            <Button
              label="Mark as IN_EDIT"
              variant="contained"
              style={{ marginRight: '10px' }}
              disabled={record.status !== AdRequestEditStatus.NOT_EDITED}
              onClick={() => {
                update(`ad-requests`, {
                  data: {
                    brandId: record.brandId,
                    status: AdRequestEditStatus.IN_EDIT,
                  },
                });
              }}
            />
            <Button
              label="Mark as EDITED"
              variant="contained"
              disabled={record.status === AdRequestEditStatus.EDITED}
              onClick={() => {
                update(`ad-requests`, {
                  data: {
                    brandId: record.brandId,
                    status: AdRequestEditStatus.EDITED,
                  },
                });
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
