import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { checkVideoSessionId } from '../../providers/dataProvider';
import { ReferenceField, TextField, TextInput, useNotify } from 'react-admin';
import { useFormContext } from 'react-hook-form';

interface Session {
  id: string;
  name: string;
}
export const AssignVideoSession: React.FC<{
  record: any;
  sessions: Session[];
  setSessions: (sessions: any) => void;
}> = ({ record, sessions, setSessions }) => {
  const [inputSessionId, setInputSessionId] = useState('');

  const notify = useNotify();
  const { setValue, unregister } = useFormContext();

  // used to set the value of sessions in state for chip list
  // unregister from react hook form to allow better control of the input value check and add to state
  // set value is used to make the form dirty and enable save when sessions are added or removed
  useEffect(() => {
    setSessions(sessions);
    unregister('sessions');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDown = (
    e: KeyboardEvent<HTMLInputElement> & { target: { value: string } },
  ) => {
    e.stopPropagation();

    if (e.key === 'Enter' && inputSessionId.length > 0) {
      e.preventDefault();
      checkVideoSessionId(inputSessionId, record?.brandId)
        .then((res: Session) => {
          if (!!res) {
            setSessions((prev: Session[]) =>
              !prev
                ? [res]
                : prev.map(s => s.id).includes(res.id)
                ? prev
                : [...prev, res],
            );
          } else {
            notify('Video session not found', {
              type: 'warning',
            });
          }
        })
        .catch(error => {
          error.body.statusCode === 404 &&
            notify('Video session not found', {
              type: 'warning',
            });
        })
        .finally(() => {
          setInputSessionId('');
          setValue('sessions', '', { shouldDirty: true });
          e.target.value = '';
        });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextInput
        source="sessions"
        format={v => undefined}
        label="Video Session ID"
        type="number"
        value={inputSessionId}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInputSessionId(e.target.value)
        }
        onKeyDown={onKeyDown}
      />

      {sessions?.map((session: Session) => (
        <div
          key={session.id}
          style={{
            marginBottom: '5%',
            background: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '25px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 15px',
            width: '90%',
          }}
        >
          <ReferenceField
            source="id"
            record={session}
            reference="videosessions"
            link={'show'}
          >
            <TextField
              source="name"
              style={{
                width: 'fit-content',
              }}
            />
          </ReferenceField>
          <span
            style={{
              fontSize: '0.75rem',
              display: 'inline-flex',
              alignItems: 'center',
              color: 'black',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSessions((prev: any[]) =>
                prev.filter(({ id }: { id: string }) => id !== session.id),
              );
              setValue('sessions', '', { shouldDirty: true });
            }}
          >
            X
          </span>
        </div>
      ))}
    </div>
  );
};
