import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { DataProvider } from 'ra-core';
import { stringify } from 'query-string';
import { convertFileToBase64, downloadBlob } from '../utils/general';
import axios, { AxiosRequestHeaders } from 'axios';
import {
  AssetCompleteUploadBody,
  CopyEmailBody,
  CreateAssetBody,
  CopyVideoSessionBody,
  UploadAssetParams,
} from '../types';
import { Library } from '../consts';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const { access_token } = JSON.parse(localStorage.getItem('auth') || '{}');
  options.headers.set('Authorization', `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  process.env.REACT_APP_API_BASE_URL,
  httpClient,
);

const prepareVideoAdParams = async (params: any) => {
  if (params.data.tags?.length > 0) {
    params.data.tags = params.data.tags.map((tag: any) => tag.id);
  }

  return params;
};

const prepareBrandParams = async (params: any) => {
  const {
    data: { logoURL, fullLogoURL, overrideGlobalTOS, customTOS },
  } = params;
  const newParams = { ...params };
  if (logoURL && logoURL.rawFile instanceof File) {
    newParams.data.logoURL = await convertFileToBase64(logoURL);
  }
  if (fullLogoURL && fullLogoURL.rawFile instanceof File) {
    newParams.data.fullLogoURL = await convertFileToBase64(fullLogoURL);
  }

  newParams.data.customTOS = overrideGlobalTOS ? customTOS : null;
  return newParams;
};

const customDataProvider: DataProvider = {
  ...dataProvider,
  create: (resource: string, params: any) => {
    if (resource === 'brands') {
      return Promise.resolve(
        prepareBrandParams(params).then(newParams =>
          dataProvider.create(resource, newParams),
        ),
      );
    }
    return dataProvider.create(resource, params);
  },
  update: (resource: string, params: any) => {
    if (resource === 'brands') {
      return Promise.resolve(
        prepareBrandParams(params).then(newParams =>
          dataProvider.update(resource, newParams),
        ),
      );
    }

    if (resource === 'videoads') {
      prepareVideoAdParams(params).then(newParams =>
        dataProvider.update(resource, newParams),
      );
    }
    return dataProvider.update(resource, params).catch((error: any) => {
      if (error.status === 400) {
        throw new Error(error?.toString());
      }
      throw error;
    });
    // TODO: unreachable code. check and uncomment if needed.
    // return dataProvider.update(resource, params);
  },
  getMany: (resource: string, params: any) => {
    // fix for adding custom params for get all sessions query.
    // Due to diff in versions, it is not possible to add custom params in getMany
    // when packages will be upgraded this need to be refactor to a more generic logic.

    if (resource === 'videosessions') {
      const url = `${
        process.env.REACT_APP_API_BASE_URL
      }/${resource}?${params.ids
        .map((id: string) => `id[]=${id}`)
        .join('&')}&includeHidden=true`;

      return httpClient(url, { method: 'GET' }).then(res => {
        return { data: res.json };
      });
    }
    return dataProvider.getMany(resource, params);
  },

  getOne: (resource: string, params: any) => {
    if (resource === 'videosessions') {
      return getVideoSessionById(params.id).then(res => {
        return { data: res.json };
      });
    } else if (resource === 'brands') {
      return httpClient(
        `${process.env.REACT_APP_API_BASE_URL}/brands/${params.id}?includeEmailSender=true`,
        { method: 'GET' },
      ).then(res => {
        return { data: res.json };
      });
    }
    return dataProvider.getOne(resource, params);
  },
};

export default customDataProvider;

export const setupIncentive = (
  campaignId: string,
  incentiveSettingId?: string,
  couponsList?: string[],
) => {
  let body: any = {};
  if (incentiveSettingId && incentiveSettingId !== '') {
    body.incentiveSettingId = incentiveSettingId;
  }
  if (couponsList && couponsList.length > 0) {
    body.couponsList = couponsList;
  }
  return httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/campaigns/${campaignId}/incentive/setup`,
    { method: 'POST', body: JSON.stringify(body) },
  );
};

export const pullIncentive = (campaignId: string, stepId?: string) => {
  let body: any = {};
  if (stepId !== '') {
    body.stepId = stepId;
  }
  return httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/campaigns/${campaignId}/incentive/pull`,
    { method: 'POST', body: JSON.stringify(body) },
  );
};

export const sendBatch = (batchId: string, campaignLaunchDate: string) =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}/batches/${batchId}/send`, {
    method: 'POST',
    body: JSON.stringify({ campaignLaunchDate }),
  });

export const downloadCustomersCSV = async (params: any, filename?: string) => {
  const query = new URLSearchParams(params).toString();
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/customers/csv?${query}`,
    {
      method: 'GET',
    },
  );

  const finalFilename = filename
    ? filename.toString().endsWith('.csv')
      ? filename
      : `${filename}.csv`
    : 'customers.csv';
  return downloadBlob(new Blob([response.body]), finalFilename);
};

export const getCustomersSample = (params: any) => {
  const query = new URLSearchParams(
    stringify(params, { skipNull: true, skipEmptyString: true }),
  ).toString();
  return httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/customers/count?${query}`,
    {
      method: 'GET',
    },
  );
};

export const testBatch = (batchId: string, toEmail: string) =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}/batches/${batchId}/test`, {
    method: 'POST',
    body: JSON.stringify({ toEmail }),
  });

export const duplicateCampaign = (campaignId: string) =>
  httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/campaigns/${campaignId}/duplicate`,
    {
      method: 'POST',
    },
  );

export const duplicateTemplate = (id: string) =>
  httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/templates/${id}/duplicate`,
    {
      method: 'POST',
    },
  );

export const reloadFromTemplates = (id: string | number) =>
  httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/campaigns/${id}/templates-reload`,
    {
      method: 'POST',
    },
  );
export const applyBrandKitOnTemplate = (campaignTemplateId: string) =>
  httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/templates/${campaignTemplateId}/kit/reset`,
    {
      method: 'PUT',
    },
  );

export const applyBrandKitOnCampaign = (campaignId: string) =>
  httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/campaigns/${campaignId}/kit/reset`,
    {
      method: 'PUT',
    },
  );

export const createVideoAd = async (body: {
  name: string;
  brandId?: string;
  platforms: string[];
  takes?: string[];
  size?: number;
  contentType?: string;
}): Promise<{
  videoAdId: string;
  versionId: string;
  signData: UploadAssetParams;
  fileName: string;
}> => {
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/videoads`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
  );

  return response.json;
};

export const createVideoAdVersion = async (
  videoAdId: string,
  takes?: string[],
  size?: number,
  contentType?: string,
) => {
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/videoads/${videoAdId}/versions`,
    {
      method: 'POST',
      body: JSON.stringify({ takes, size, contentType }),
    },
  );

  return response.json;
};

export const checkEmailSenderVerification = async (brandId: any) => {
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/brands/${brandId}/emailsender/verified`,
    {
      method: 'GET',
    },
  );

  return response.json;
};

export const sendTestEmail = (emailId: string, addressees: string[]) =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}/email/${emailId}/test`, {
    method: 'POST',
    body: JSON.stringify({ addressees }),
  });

export const exportEmail = (emailId: string, provider: string) =>
  httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/email/${emailId}/export?provider=${provider}`,
    {
      method: 'GET',
    },
  );

export const getTakeById = (takeId: string) => {
  return httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/videotakes/${takeId}`,
    {
      method: 'GET',
    },
  );
};

export const getCampaignById = (campaignId: string) => {
  return httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/campaigns/${campaignId}`,
    {
      method: 'GET',
    },
  );
};

export const uploadCustomers = async (brandId: string, file: File) => {
  const body = new FormData();
  body.append('file', file);
  body.append('brandId', `${brandId}`);

  return httpClient(`${process.env.REACT_APP_API_BASE_URL}/customers/upload`, {
    method: 'POST',
    body,
    headers: new Headers(),
  });
};

export const resendIncentive = (id: string) =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}/incentives/${id}/resend`, {
    method: 'POST',
  });

export const transcribeVideoTake = (id: string) =>
  httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/videotakes/${id}/transcribe`,
    { method: 'POST' },
  );

export const getVideoSessionById = (sessionId: string) => {
  const query = {
    includeHiddenTakes: 'true',
    includeUnconfirmedTakes: 'true',
    library: Library.UGC,
  };

  return httpClient(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/library/${sessionId}?${new URLSearchParams(query).toString()}`,
    {
      method: 'GET',
    },
  );
};
export const getTakeMetadata = (takeId: any) =>
  httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/videotakes/${takeId}/metadata`,
    {
      method: 'GET',
    },
  );

export const uploadFile = (file: File, tag?: string) => {
  const body = new FormData();
  body.append('file', file, file.name);
  if (tag) body.append('tag', tag);
  return httpClient(`${process.env.REACT_APP_API_BASE_URL}/upload`, {
    method: 'POST',
    body,
  });
};

export const uploadFileFromPresignURL = (
  url: string,
  file: File,
  headers: AxiosRequestHeaders | undefined = undefined,
) => {
  return axios.put(url, file, { headers });
};

export const createFileUploadURL = (fileName: string, tag?: string) =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}/upload/url`, {
    method: 'POST',
    body: JSON.stringify({ fileName, tag }),
  });

export const uploadFileWithURL = async (file: File, tag?: string) => {
  const urlRes = await createFileUploadURL(file.name, tag);
  const { presignedUrl, url } = urlRes.json;
  try {
    await uploadFileFromPresignURL(presignedUrl, file, {
      'Content-Type': file.type,
    });
  } catch (error) {
    console.error('failed to upload using presigned url: ', error);
  }
  return url;
};

export const updateUserPassword = async (
  userId: string,
  body: {
    newPassword: string;
  },
) => {
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/update-password`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
  );

  return response.json;
};

export const createAsset = (data: CreateAssetBody) =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}/assets`, {
    method: 'POST',
    body: JSON.stringify({ ...data, isMultipart: true }),
  });

export const generateQuestions = async (
  campaignId: string,
  brandName: string,
  brandDescription: string,
  valuePropositions: string[],
  targetAudience: string,
  theme: string,
  tones: string[],
  emotionalScale: string,
  valueScale: string,
  incentive: string[],
) => {
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/campaigns/${campaignId}/questions`,
    {
      method: 'POST',
      body: JSON.stringify({
        brandName,
        brandDescription,
        valuePropositions,
        targetAudience,
        theme,
        tones,
        emotionalScale,
        valueScale,
        //incentive,
      }),
    },
  );
  return response.json;
};

export const checkVideoSessionId = async (
  sessionId: string,
  brandId: string,
) => {
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/videosessions/${sessionId}?brandId=${brandId}`,
    {
      method: 'GET',
    },
  );
  return response.status === 200 ? response.json : null;
};

export const updateVideoAd = async (data: any) => {
  data = await prepareVideoAdParams(data);

  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/videoads/${data.id}`,
    {
      method: 'PUT',
      body: JSON.stringify(data.data),
    },
  );
  return response.json;
};

export const onAssetsCompleteUpload = (
  assetId: string,
  data: AssetCompleteUploadBody,
) => {
  return httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/assets/${assetId}/complete-upload`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  ).then(res => res.json);
};

export const copyEmail = async (data: CopyEmailBody) => {
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/email/copy`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  );

  return response.json;
};

export const copyVideoSession = async (data: CopyVideoSessionBody) => {
  const { videoSessionId, campaignId } = data;
  const response = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/campaigns/${campaignId}/copyVideoSession`,
    {
      method: 'POST',
      body: JSON.stringify({
        videoSessionId,
      }),
    },
  );

  return response.json;
};

export type EntityIdDto = {
  id: string;
  library: Library;
};

export type LibraryActionPayload =
  | { brandId: string; entityIds: EntityIdDto[] }
  | { brandId: string; query: UseSearchQueryParams };

export type UseSearchQueryParams = Partial<{
  type: string[];
  settings: string[];
  angleOfView: string[];
  ageGroups: string[];
  genders: string[];
  locales: string[];
  fromDate: Date | null;
  toDate: Date | null;
  library: string[];
  tags: number[] | string[];
  sort: null | string;
  order: null | 'asc' | 'desc';
  numberOfPeople: string[];
  keywords?: string[];
  parentId: string | number | null;
  includeSubFolders?: boolean;
  shouldApplyAll?: boolean;
  transcriptionBreakdownTypes: {
    type: string;
    subType?: string;
  }[];
}>;

export const generateDownloadStreamUrl = async (
  payload: LibraryActionPayload,
) => {
  const { json } = await httpClient(
    `${process.env.REACT_APP_API_BASE_URL}/library/download-stream`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );

  return (json as { href: string }).href;
};
