import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  DeleteButton,
  Edit,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  email,
  required,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../utils/general';

export const Role = {
  CUSTOMER: 'CUSTOMER',
  EDITOR: 'EDITOR',
  MODERATOR: 'MODERATOR',
  ADMIN: 'ADMIN',
};

const Roles = [
  { id: Role.CUSTOMER, name: 'Customer' },
  { id: Role.EDITOR, name: 'Editor' },
  { id: Role.MODERATOR, name: 'Moderator' },
  { id: Role.ADMIN, name: 'Admin' },
];

export const UserCreate: React.FC = (props: any) => {
  const query = useQuery();
  const accountIdStr = query.get('accountId');
  const accountId = accountIdStr ? parseInt(accountIdStr as string, 10) : '';
  const notify = useNotify();
  const redirect = useRedirect();
  const redirectDest = accountId ? `/accounts/${accountId}/` : false;

  return (
    <Create
      {...props}
      mutationOptions={{
        onSuccess: (data: any) => {
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
          redirect(redirectDest);
        },
      }}
    >
      <SimpleForm>
        <ReferenceInput
          source="accountId"
          reference="accounts"
          disabled
          defaultValue={accountId}
          isRequired
          validate={[required()]}
        >
          <AutocompleteInput optionText="name" defaultValue={accountId} />
        </ReferenceInput>
        <TextInput source="email" isRequired validate={[required(), email()]} />
        <TextInput source="firstName" isRequired validate={[required()]} />
        <TextInput source="lastName" isRequired validate={[required()]} />
        <BooleanInput
          source="isSuperUser"
          defaultValue={false}
          isRequired
          validate={[required()]}
        />
        <SelectInput
          source="role"
          label="Role"
          choices={Roles}
          defaultValue="CUSTOMER"
          isRequired
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export const UserEdit: React.FC = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: (data: any) => {
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
          redirect(`/accounts/${data.accountId}/`);
        },
      }}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="firstName" isRequired validate={[required()]} />
        <TextInput source="lastName" isRequired validate={[required()]} />
        <TextInput source="email" isRequired validate={[required()]} />
        <SelectInput
          source="role"
          label="Role"
          choices={Roles}
          validate={[required()]}
        />
        <BooleanInput source="isSuperUser" label="Super User?" />
      </SimpleForm>
    </Edit>
  );
};

const CustomToolbar = () => {
  const notify = useNotify();
  const navigate = useNavigate();

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteButton
        mutationOptions={{
          onSettled: (_, err) => {
            if (!err) {
              notify('User deleted', { type: 'info' });
              // Navigates back to the account page
              navigate(-2);
            }
          },
        }}
        confirmContent="Are you sure you want to deleted this user?"
        confirmTitle="Delete User"
        mutationMode="pessimistic"
        label="Delete"
      />
    </Toolbar>
  );
};
