import {
  Button,
  Create,
  Datagrid,
  DateField,
  FileField,
  FileInput,
  NumberField,
  Pagination,
  RaRecord,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
  useListContext,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import {
  downloadCustomersCSV,
  getCustomersSample,
  uploadCustomers,
} from '../../providers/dataProvider';
import { useQuery } from '../../utils/general';
import React from 'react';
import { Chip, CircularProgress, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom';

const AddCustomersButton = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Button
      type="button"
      variant="contained"
      label="Add Customers"
      component={Link}
      to={`/customers/create?brandId=${record?.id}`}
    >
      <AddIcon />
    </Button>
  );
};

export const DownloadField = (props: any) => {
  const record = useRecordContext(props);
  return !record ? null : (
    <Button
      type="button"
      variant="text"
      label="download"
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        downloadCustomersCSV({
          brandId: record.brandId,
          uploadIds: [record.id],
          castingType: 'UPLOADED',
          excludePreviousBatches: false,
          batchSize: record.linkedCustomers,
        });
      }}
    >
      <DownloadIcon />
    </Button>
  );
};

const STATE_TO_CHIP: Record<string, JSX.Element> = {
  Success: <Chip color="success" label="Success" />,
  Failure: <Chip color="error" label="Failure" />,
  Processing: <Chip color="info" label="Processing" />,
};

const StateField = (props: any) => {
  const record = useRecordContext(props);
  return (
    (record && STATE_TO_CHIP[record.state]) || (
      <Chip color="warning" label="Unknown" />
    )
  );
};

const BulkUploadsActions = (props: any) => {
  const { selectedIds } = useListContext(props);
  return (
    <Button
      type="button"
      variant="text"
      label="download"
      onClick={async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      ) => {
        const downloads = selectedIds.map(id =>
          downloadCustomersCSV(
            {
              uploadId: id,
              castingType: 'UPLOADED',
            },
            id,
          ),
        );

        await Promise.all(downloads);
      }}
    >
      <DownloadIcon />
    </Button>
  );
};

export const UploadsFields = (record?: RaRecord) => [
  <TextField source="id" key="id" {...(record ? { record } : {})} />,
  <DateField
    source="createdAt"
    key="createdAt"
    showTime
    {...(record ? { record } : {})}
  />,
  <StateField
    source="state"
    key="state"
    sortable={false}
    {...(record ? { record } : {})}
  />,
  <NumberField
    source="fileName"
    key="fileName"
    label="Original file name"
    sortable={false}
    {...(record ? { record } : {})}
  />,
  <NumberField
    source="fileSize"
    key="fileSize"
    label="Size (bytes)"
    sortable={false}
    {...(record ? { record } : {})}
  />,
  <NumberField
    source="total"
    key="total"
    sortable={false}
    {...(record ? { record } : {})}
  />,
  <NumberField
    source="valid"
    key="valid"
    sortable={false}
    {...(record ? { record } : {})}
  />,
  <NumberField
    source="duplicate"
    key="duplicate"
    sortable={false}
    {...(record ? { record } : {})}
  />,
  <NumberField
    source="existing"
    key="existing"
    sortable={false}
    {...(record ? { record } : {})}
  />,
  <NumberField
    source="linkedCustomers"
    key="linkedCustomers"
    label="Customers linked to file"
    sortable={false}
    {...(record ? { record } : {})}
  />,
  <DownloadField sortable={false} key="download" />,
];

export const UploadsList: React.FC = (props: any) => {
  const record = useRecordContext(props);
  const [totalBrandCustomers, setTotalBrandCustomers] =
    React.useState<number>(0);
  const [error, setError] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    async function getCustomers() {
      try {
        if (record.id) {
          const res = await getCustomersSample({
            brandId: record.id,
            castingType: 'UPLOADED',
            excludePreviousBatches: false,
          });
          setTotalBrandCustomers(res.json.total);
        }
      } catch (e: any) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }

    getCustomers();
  }, [record?.id]);

  return (
    <>
      <AddCustomersButton />
      <Typography variant="h6">
        Total brand uploaded customers:{' '}
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <ErrorIcon color="error" />
        ) : (
          totalBrandCustomers
        )}
      </Typography>
      <ReferenceManyField
        reference="customers/uploads"
        resource="customers/uploads"
        target="brandId"
        sort={{ field: 'createdAt', order: 'desc' }}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={<BulkUploadsActions />}>
          {UploadsFields()}
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

export const UploadCustomers: React.FC = (props: any) => {
  const query = useQuery();
  const brandIdStr = query.get('brandId');
  const brandId = brandIdStr ? parseInt(brandIdStr as string, 10) : '';
  const redirect = useRedirect();

  return (
    <Create>
      <SimpleForm
        onSubmit={async (data: any) => {
          await uploadCustomers(data.brandId, data.upload.rawFile);
          redirect(`/brands/${brandId}/6`);
        }}
        defaultValues={{ brandId }}
        toolbar={
          <Toolbar>
            <SaveButton label="Upload" icon={<UploadFileIcon />} />
          </Toolbar>
        }
      >
        <ReferenceInput
          source="brandId"
          reference="brands"
          isRequired
          validate={[required()]}
        >
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <FileInput
          source="upload"
          label=""
          accept="text/csv"
          validate={[required()]}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
