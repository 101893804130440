import { AddBox, DeleteForeverOutlined } from '@mui/icons-material';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useCreate, useDelete, useNotify } from 'react-admin';
import { Affinity, AgeGroup, Ethnicity, Gender } from '../../consts';
import { SingleSelect } from '../CustomInputs/Select';

interface PersonsProps {
  videoTakeId: any;
  persons: any;
  setPersons: (data: any) => void;
}
interface NewPersonProps {
  id?: any;
  ageGroup: typeof AgeGroup;
  ethnicity: typeof Ethnicity;
  affinity: typeof Affinity;
  gender: typeof Gender;
}

const PersonsDisplay: React.FC<PersonsProps> = ({
  videoTakeId,
  persons,
  setPersons,
}) => {
  const [displayAddRow, setDisplayAddRow] = useState(false);
  const [newPerson, setNewPerson] = useState<NewPersonProps | null>(null);
  const [
    create,
    { data: createData, isLoading: isCreateLoading, error: createError },
  ] = useCreate<any, any>();
  const [deleteOne, { error: deleteError }] = useDelete<any, any>();
  const notify = useNotify();
  useEffect(() => {
    if (createError) {
      notify(
        `Failed to create new persona. ${createError?.message || createError}`,
        { type: 'warning' },
      );
      return;
    }
    if (deleteError) {
      notify(
        `Failed to delete persona. ${deleteError?.message || deleteError}`,
        { type: 'warning' },
      );
      return;
    }
  }, [createError, deleteError, notify]);

  useEffect(() => {
    if (
      !createError &&
      newPerson &&
      !isCreateLoading &&
      createData?.id &&
      !persons.find((p: any) => p.id === createData.id)
    ) {
      setPersons((prev: any) => [...prev, createData]);
      setNewPerson(null);
      setDisplayAddRow(false);
      notify('Created new persona', { type: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, createError, isCreateLoading]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: '3%',
        marginTop: '5%',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography style={{ textDecorationLine: 'underline' }} variant="h6">
          Personas
        </Typography>
        <AddBox
          style={{ paddingTop: '0.5%', marginLeft: '1%', cursor: 'pointer' }}
          onClick={() => {
            setDisplayAddRow(true);
          }}
        />
      </div>
      {displayAddRow && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignContent: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '20%' }}>
            <SingleSelect
              title="Gender"
              labelId="gender"
              onChange={e => {
                setNewPerson((prev: any) => ({
                  ...prev,
                  gender: e.target.value,
                }));
              }}
              options={Gender}
              value={newPerson?.gender}
            />
          </div>

          <div style={{ width: '20%' }}>
            <SingleSelect
              title="Age Group"
              labelId="ageGroup"
              onChange={e => {
                setNewPerson((prev: any) => ({
                  ...prev,
                  ageGroup: e.target.value,
                }));
              }}
              options={AgeGroup}
              value={newPerson?.ageGroup}
            />
          </div>

          <div style={{ width: '20%' }}>
            <SingleSelect
              title="Ethnicity"
              labelId="ethnicity"
              onChange={e => {
                setNewPerson((prev: any) => ({
                  ...prev,
                  ethnicity: e.target.value,
                }));
              }}
              options={Ethnicity}
              value={newPerson?.ethnicity}
            />
          </div>

          <div style={{ width: '15%' }}>
            <SingleSelect
              title="Affinity"
              labelId="affinity"
              onChange={e => {
                setNewPerson((prev: any) => ({
                  ...prev,
                  affinity: e.target.value,
                }));
              }}
              options={Affinity}
              value={newPerson?.affinity}
            />
          </div>

          <Button
            variant="outlined"
            style={{
              height: 'fit-content',
              marginTop: '2%',
              marginLeft: '3%',
            }}
            onClick={() => {
              if (!newPerson) {
                notify('Persona must include at least one field', {
                  type: 'warning',
                });
                return;
              }
              create(`videotakes/${videoTakeId}/metadata/persona`, {
                data: { ...newPerson },
              });
            }}
          >
            {isCreateLoading ? 'Loading' : 'Add'}
          </Button>
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Gender</TableCell>
            <TableCell align="left">Age Group</TableCell>
            <TableCell align="left">Ethnicity</TableCell>
            <TableCell align="left">Affinity</TableCell>
            <TableCell style={{ width: '5%' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {persons.map((person: any, index: number) => (
            <TableRow key={`${person.id}_${index}`}>
              <TableCell align="left">
                {Gender[person.gender as keyof typeof Gender]}
              </TableCell>
              <TableCell align="left">
                {AgeGroup[person.ageGroup as keyof typeof AgeGroup]}
              </TableCell>
              <TableCell align="left">
                {Ethnicity[person.ethnicity as keyof typeof Ethnicity]}
              </TableCell>
              <TableCell align="left">
                {Affinity[person.affinity as keyof typeof Affinity]}
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    deleteOne(`videotakes/${videoTakeId}/metadata/persona`, {
                      id: person.id,
                    }).then(() => {
                      if (!deleteError) {
                        setPersons((prev: any) =>
                          prev.filter((p: any) => p.id !== person.id),
                        );
                        notify('Persona deleted', { type: 'info' });
                      }
                    });
                  }}
                >
                  <DeleteForeverOutlined style={{ color: 'red' }} />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default PersonsDisplay;
