import React from 'react';
import { Typography } from '@mui/material';
import { Button, useNotify } from 'react-admin';
import { pullIncentive } from '../../../providers/dataProvider';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useFormContext } from 'react-hook-form';

const PullIncentiveButton: React.FC<{
  record?: any;
  stepIdSource?: string;
}> = props => {
  const {
    record: {
      id: campaignId,
      _count: { coupons = 0 } = {},
      incentiveSetting = {},
    } = {},
  } = props;
  const { getValues } = useFormContext();
  const stepId = getValues(props.stepIdSource as string);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [jobStatus, setJobStatus] = React.useState<string>('');
  const [couponCount, setCouponCount] = React.useState<number | null>(null);
  const notify = useNotify();
  return (
    <div style={{ marginTop: '20px' }}>
      <Typography variant="body1">
        <b>Coupon job:</b> {/* @ts-ignore */}
        {jobStatus ||
          incentiveSetting?.metadata?.discountJobStatus ||
          'Not started'}
      </Typography>
      <Typography variant="body1">
        <b>Coupons pulled:</b> {couponCount || coupons}
      </Typography>
      <Button
        label="Pull coupons"
        variant="outlined"
        disabled={
          isLoading ||
          (jobStatus || incentiveSetting?.metadata?.discountJobStatus) ===
            'DONE'
        }
        onClick={async () => {
          try {
            setIsLoading(true);
            const res = await pullIncentive(campaignId, stepId);
            setJobStatus(res.json.status);
            setCouponCount(res.json.couponCount);
          } catch (err) {
            console.error(err);
            notify(`Failed to pull incentive. Check the console for errors`, {
              type: 'error',
            });
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <AutorenewIcon />
      </Button>
    </div>
  );
};

export default PullIncentiveButton;
