import React from 'react';
import {
  Create,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRedirect,
} from 'react-admin';
import { useQuery } from '../utils/general';

const INTEGRATION_CHOICES = [
  //   { id: "SHOPIFY", name: "Shopify" },
  { id: 'KLAVIYO', name: 'Klaviyo' },
  { id: 'WEBHOOKS', name: 'Webhooks' },
];

export const IntegrationCreate: React.FC = (props: any) => {
  const query = useQuery();
  const redirect = useRedirect();
  const brandIdStr = query.get('brandId');
  const brandId = brandIdStr ? parseInt(brandIdStr as string, 10) : '';
  return (
    <Create
      {...props}
      mutationOptions={{
        onSuccess: () => {
          if (brandId) redirect(`/brands/${brandId}/3`);
        },
      }}
    >
      <SimpleForm defaultValues={{ brandId }}>
        <ReferenceInput
          source="brandId"
          reference="brands"
          label="Brand"
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          source="vendor"
          choices={INTEGRATION_CHOICES}
          isRequired
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.vendor === 'KLAVIYO' && (
              <TextInput
                source="settings.apiKey"
                isRequired
                validate={[required()]}
                label="API Key"
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
