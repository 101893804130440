/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import _ from 'lodash';
import { useNotify, useUpdate } from 'ra-core';
import { useCallback, useEffect, useState } from 'react';
import { getTakeMetadata } from '../../providers/dataProvider';

import MetadataDisplay from './MetadataDisplay';
import PersonsDisplay from './PersonsDisplay';

interface VideoTakeMetaProps {
  campaignId: any;
  videoTakeId?: string;
  selectedVideoRef?: React.MutableRefObject<HTMLVideoElement | null>;
}

export const VideoTakeMeta: React.FC<VideoTakeMetaProps> = ({
  campaignId,
  videoTakeId,
  selectedVideoRef,
}) => {
  const [metadata, setMetadata] = useState<{ [key: string]: any } | null>(null);
  const [persons, setPersons] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);

  const [update, { isLoading: isUpdateLoading, error: updateError }] =
    useUpdate<any, any>();

  const notify = useNotify();

  const submitMetaUpdate = (
    takeId: string,
    field?: string,
    value?: string | string[],
  ) => {
    if (!isUpdateLoading && field) {
      update(`videotakes`, {
        id: `${takeId}/metadata`,
        data: { ...(metadata || {}), [field]: value || null },
      }).then(() => {
        if (!updateError) {
          notify('Metadata updated', { type: 'success' });
          return;
        }
      });
    }
  };

  const debounceSubmit = useCallback(_.debounce(submitMetaUpdate, 500), []);

  const onUpdateField = (fieldKey: string, value?: string | string[]) => {
    setMetadata(prev => ({ ...prev, [fieldKey]: value }));
    videoTakeId && debounceSubmit(videoTakeId, fieldKey, value);
  };

  useEffect(() => {
    if (!videoTakeId) {
      return;
    }
    setIsDataLoading(true);
    getTakeMetadata(videoTakeId)
      .then(({ json }: any) => {
        if (json) {
          setMetadata(
            Object.fromEntries(
              Object.entries(json).filter(([key]) => key !== 'persons'),
            ),
          );
          setPersons(json.persons);
          setIsDataError(false);
        } else {
          notify('No Metadata Found', { type: 'warning' });
        }
      })
      .catch((err: any) => {
        notify(`Error getting metadata. ${err?.message || err}`, {
          type: 'warning',
        });
        setIsDataError(true);
      })
      .finally(() => setIsDataLoading(false));
  }, [videoTakeId]);

  useEffect(() => {
    if (updateError) {
      notify(
        `Error updating metadata. ${updateError?.message || updateError}`,
        { type: 'warning' },
      );
      return;
    }
  }, [updateError, isUpdateLoading, notify]);

  if (isDataLoading) {
    return <>Loading...</>;
  }
  if (isDataError) {
    return <>No Meta Data Found</>;
  }

  return (
    <div
      style={{
        paddingLeft: '0.5%',
        width: '100%',
      }}
    >
      <Box style={{ width: '100%' }}>
        <PersonsDisplay
          videoTakeId={videoTakeId}
          persons={persons}
          setPersons={setPersons}
        />
        <MetadataDisplay
          metadata={metadata}
          campaignId={campaignId}
          onUpdateField={onUpdateField}
        />
      </Box>
    </div>
  );
};
