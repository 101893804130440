import { GuidelineType } from './consts';

export enum AssetType {
  FOLDER = 'FOLDER',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  ANIMATION = 'ANIMATION',
  DOCUMENT = 'DOCUMENT',
  OTHER = 'OTHER',
}

export enum AdRequestEditStatus {
  NOT_EDITED = 'NOT_EDITED',
  IN_EDIT = 'IN_EDIT',
  EDITED = 'EDITED',
}

export type CreateAssetBody = {
  name: string;
  type: AssetType;
  brandId: string;
  parentId?: string | null;
  library?: string;
  versionId?: string;
  takeId?: string;
};

export interface AssetCompleteUploadBody {
  uploadId: string;
  parts: {
    eTag: string;
    partNumber: number;
  }[];
}

export type UploadAssetParams = {
  presignedURL?: string;
  presignedUrls?: string[];
  id: string;
  uploadId?: string;
};

export type CopyEmailBody = {
  emailId: string;
  campaignTemplateId?: string;
  campaignId?: string;
};

export type Guideline = {
  id: string;
  brandId: string;
  type: GuidelineType;
  text: string;
};

export type AdRequest = {
  id: string;
  brandId: string;
  brand: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  videoSession?: {
    id: string;
    name: string;
  };
  videoAd?: {
    id: string;
    name: string;
  };
  asset?: {
    id: string;
    name: string;
  };
  assetId?: string;
  videoAdId?: string;
  videoSessionId?: string;
  adObjective: string;
  numOfHooks: number;
  platforms: string[];
  sizes: string[];
  tones: string[];
  editingStyle: string;
  additionalAssetIds: number[];
  createdAt: Date | null;
  status: keyof typeof AdRequestEditStatus;
  guidelines?: Guideline[];
};

export type GuidelineMapping = {
  [GuidelineType.CONSTRAINTS]?: Guideline[];
  [GuidelineType.SALE_RESISTANCE]?: Guideline[];
  [GuidelineType.VALUE_PROPS]?: Guideline[];
};

export type CopyVideoSessionBody = {
  videoSessionId: string;
  campaignId: string;
};

export enum BrandVettingMethod {
  BRAND = 'BRAND',
  MODERATOR = 'MODERATOR',
}

export type LanguageLocale = {
  id: string;
  shortId: string;
  name: string;
  shortEngName: string;
};
