import { format } from 'date-fns';
import { CurrencySignMap, IncentiveProvision } from '../../consts';
import { Typography } from '@mui/material';

type Props = {
  incentives: {
    deliveredAt: Date;
    incentiveSetting: {
      settings: {
        value: string;
        locale?: string;
      };
      type: string;
      incentiveProvision: string;
    };
  }[];
};
export const VideoSessionIncentivesList: React.FC<Props> = ({ incentives }) => {
  return (
    <>
      {!!incentives?.length &&
        incentives?.map(
          ({
            deliveredAt,
            incentiveSetting: { settings, type, incentiveProvision },
          }) => {
            return (
              <Typography variant="body2" fontSize={12} width={'max-content'}>
                {settings.value}
                {CurrencySignMap[
                  settings.locale as keyof typeof CurrencySignMap
                ] || '$'}{' '}
                {type} |{' '}
                {
                  IncentiveProvision[
                    incentiveProvision as keyof typeof IncentiveProvision
                  ]
                }{' '}
                | {format(new Date(deliveredAt), 'dd/MM/yyyy')}
              </Typography>
            );
          },
        )}
    </>
  );
};

export default VideoSessionIncentivesList;
