import React from 'react';
import {
  ArrayField,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  FormDataConsumer,
  FormTab,
  FunctionField,
  NumberField,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TabbedFormTabs,
  TextInput,
  useRecordContext,
  TextField,
  Labeled,
  AutocompleteInput,
} from 'react-admin';
import { Typography } from '@mui/material';
import StepsFormTab from '../Steps/StepsFormTab';
import EmailFormTab from '../RequestTemplate/EmailFormTab';

import SendBatchButton from './SendBatchButton';
import CreateBatchButton from './CreateBatchButton';

import CampaignEditActions from './CampaignEditActions';
import BatchMembersPanel from './BatchMembersPanel';
import Casting from './Casting';
import {
  emailProvidersChoices,
  SupportedLocalesChoices,
} from '../../../consts';
import { CampaignIncentives } from '../Incentives/CampaignIncentives';
import {
  cleanEmptyValues,
  EmptyTextualFieldProps,
} from '../../../utils/general';
import IncentivesListSetup from '../IncentiveSetup/IncentiveListSetup';
import GptGenerator from '../gptGenerator/GptGenerator';
import { VettedInvitationSetup } from './VettedInvitationSetup';
import { stickyToolbar } from '../../../resources/toolbar';
import StepsGallery from '../StepsGallery/StepsGallery';

const timeSlotsChoices = [
  { id: 0, name: '0:00' },
  { id: 1, name: '1:00' },
  { id: 2, name: '2:00' },
  { id: 3, name: '3:00' },
  { id: 4, name: '4:00' },
  { id: 5, name: '5:00' },
  { id: 6, name: '6:00' },
  { id: 7, name: '7:00' },
  { id: 8, name: '8:00' },
  { id: 9, name: '9:00' },
  { id: 10, name: '10:00' },
  { id: 11, name: '11:00' },
  { id: 12, name: '12:00' },
  { id: 13, name: '13:00' },
  { id: 14, name: '14:00' },
  { id: 15, name: '15:00' },
  { id: 16, name: '16:00' },
  { id: 17, name: '17:00' },
  { id: 18, name: '18:00' },
  { id: 19, name: '19:00' },
  { id: 20, name: '20:00' },
  { id: 21, name: '21:00' },
  { id: 22, name: '22:00' },
  { id: 23, name: '23:00' },
];

const validateCampaignEdit = (values: any) => {
  const errors: any = {};
  const {
    isAutoScheduling,
    emails,
    timeSlots,
    brand: { emailSender = {} } = {},
  } = values;
  if (isAutoScheduling) {
    const requestEmail = emails.find((e: any) => e.type === 'CAMPAIGN_REQUEST');
    if (!requestEmail) {
      errors.isAutoScheduling = 'Request Email must be configured';
    } else if (
      !(emailSender && emailSender.fromEmail && emailSender.verified)
    ) {
      errors.isAutoScheduling = 'Email Sender must be configured and verified';
    } else if (!(Array.isArray(timeSlots) && timeSlots.length > 0)) {
      errors.timeSlots = 'At least one time slot should be selected';
    }
  }
  return errors;
};

const CampaignTitle: React.FC = () => {
  const record = useRecordContext();
  return <span>{`${record?.title} (${record?.id})`}</span>;
};

export const CampaignEdit: React.FC = (props: any) => {
  const [vetSetupEnabled, setVetSetupEnabled] = React.useState(false);
  const getPercent = (metric: number, denominator: number) =>
    `${Math.round((metric * 100 * 100) / denominator) / 100}%`;

  return (
    <Edit
      {...props}
      redirect={true}
      className={stickyToolbar().root}
      actions={<CampaignEditActions />}
      mutationMode="pessimistic"
      mutationOptions={{
        onMutate: (data: any) => {
          // validation for vetted invite setup on mutate
          const metadata = data?.data?.nextCampaignMetadata;
          if (metadata && vetSetupEnabled) {
            const hasAllFields = Object.entries(metadata).every(
              ([key, value]) => {
                if (key === 'enabled') {
                  return true;
                }
                return value !== undefined && value !== null;
              },
            );
            if (!hasAllFields) {
              return Promise.reject(
                new Error('Vetted Invitation Setup must be configured'),
              );
            }
            metadata.onlyFirstTimeVetted =
              metadata.onlyFirstTimeVetted === 'first';
            data.data.nextCampaignMetadata = metadata;
          } else if (!vetSetupEnabled) {
            data.data.nextCampaignMetadata = null;
          }
          return data;
        },
      }}
      transform={data => {
        const res = cleanEmptyValues(data, true, true);

        return res;
      }}
      title={<CampaignTitle />}
    >
      <TabbedForm
        tabs={<TabbedFormTabs variant="scrollable" scrollButtons={false} />}
        validate={validateCampaignEdit}
      >
        <FormTab label="summary">
          <Typography variant="h5">Campaign details</Typography>
          <TextInput source="id" disabled />
          <ReferenceInput
            source="brandId"
            reference="brands"
            isRequired
            validate={[required()]}
            perPage={100}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <TextInput
            source="title"
            isRequired
            validate={[required()]}
            {...EmptyTextualFieldProps}
          />
          <BooleanInput source="isActive" />
          <BooleanInput source="avoidDesktop" />
          <BooleanInput
            source="isAutoConsent"
            label="Should give automatic consent"
            defaultValue={true}
          />
          <DateInput source="startDate" />
          <DateInput source="endDate" />
          <NumberInput
            label="Maximum incoming videos"
            source="videoLimit"
            isRequired
            validate={[required()]}
          />
          <NumberInput
            source="desiredVettedVideos"
            isRequired
            validate={[required()]}
          />
          <NumberInput source="cycleDays" isRequired validate={[required()]} />
          <SelectInput
            source="language"
            label="Video Studio Language"
            defaultValue="en"
            choices={SupportedLocalesChoices}
            optionValue="shortId"
            optionText="shortEngName"
            isRequired
            validate={[required()]}
          />
          <BooleanInput source="autoModerate" defaultValue={false} />

          <Typography variant="h6">Templates</Typography>
          <ReferenceInput
            source="campaignTemplateId"
            reference="templates"
            label="Step Template"
            allowEmpty
            {...EmptyTextualFieldProps}
          >
            <SelectInput optionText="name" {...EmptyTextualFieldProps} />
          </ReferenceInput>
          <SelectInput
            source="emailProvider"
            choices={emailProvidersChoices}
            label="Outreach channel"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <RadioButtonGroupInput
                source="klaviyoMode"
                disabled={formData.emailProvider !== 'KLAVIYO'}
                choices={[
                  { id: 'CAMPAIGN', name: 'Campaign' },
                  { id: 'FLOW', name: 'Flow' },
                ]}
                defaultValue="CAMPAIGN"
                {...rest}
              />
            )}
          </FormDataConsumer>
          <Typography variant="h6">Sessions</Typography>
          <NumberInput
            source="maxNewCreatorSessions"
            label="Max. Creator Sessions"
          />
          <NumberInput
            source="maxRetakeCreatorSessions"
            label="Max. Creator Retakes"
          />
          <Labeled label="Continuing campaign">
            <VettedInvitationSetup
              enabled={vetSetupEnabled}
              setEnabled={setVetSetupEnabled}
            />
          </Labeled>
        </FormTab>
        <StepsFormTab label="Steps" target="campaignId" />
        <Casting label="Casting" target="campaignId" />
        <FormTab label="Batches">
          <BooleanInput
            source="isAutoScheduling"
            label="Automated Scheduling"
          />
          <NumberInput source="reminderDelay" label="Reminder Delay (Days)" />
          <ReferenceManyField
            reference="batches"
            target="campaignId"
            label={false}
          >
            <Datagrid expand={<BatchMembersPanel />} bulkActionButtons={false}>
              <FunctionField
                label="Batch"
                render={(record: any) => (
                  <span>
                    Batch No. {record.sequence} ({record.members.length})
                  </span>
                )}
              />
              <TextField source="status" />
              <FunctionField
                label="Video Submissions"
                render={(record: any) => (
                  <span>
                    {record.videoSubmissions} (
                    {getPercent(record.videoSubmissions, record.members.length)}
                    )
                  </span>
                )}
              />
              <ArrayField source="emailCampaigns">
                <Datagrid bulkActionButtons={false}>
                  <DateField source="launchDate" showTime />
                  <BooleanField source="isReminder" />
                  <NumberField source="delivered" />
                  <FunctionField
                    label="Unique Opens"
                    render={(record: any) => (
                      <span>
                        {record.uniqueOpens} (
                        {getPercent(record.uniqueOpens, record.delivered)})
                      </span>
                    )}
                  />
                  <FunctionField
                    label="Unique Clicks"
                    render={(record: any) => (
                      <span>
                        {record.uniqueClicks} (
                        {getPercent(record.uniqueClicks, record.delivered)})
                      </span>
                    )}
                  />
                  <NumberField source="unsubscribes" />
                  <NumberField source="spamReports" />
                </Datagrid>
              </ArrayField>

              <SendBatchButton />
              <DeleteButton mutationMode="optimistic" redirect={false} />
            </Datagrid>
          </ReferenceManyField>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.isAutoScheduling ? (
                <SelectArrayInput
                  source="timeSlots"
                  choices={timeSlotsChoices}
                  label="Time Slots (EST)"
                  {...rest}
                />
              ) : (
                <CreateBatchButton />
              )
            }
          </FormDataConsumer>
        </FormTab>
        <EmailFormTab target="campaignId" label={'Emails'} />
        <FormTab label="Incentive Setup">
          <FormDataConsumer>
            {({ formData, getSource, ...rest }) => (
              <IncentivesListSetup formData={formData} getSource />
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Incentives">
          <CampaignIncentives />
        </FormTab>
        <FormTab label="Questions Generator">
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <GptGenerator
                campaignId={formData.id}
                brandId={formData.brand?.id}
                guidelines={formData.brand?.guidelines}
                incentiveSetting={formData?.incentiveSetting}
              />
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Steps Gallery">
          <FormDataConsumer>
            {({ formData, ...rest }) => <StepsGallery />}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
