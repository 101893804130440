import {
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  SelectArrayInput,
  FunctionField,
  Button,
  FileInput,
  SaveButton,
  DateField,
  useNotify,
  useRecordContext,
  Labeled,
  useRefresh,
  usePermissions,
} from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import { Upload } from '@mui/icons-material';
import { platformsChoices } from './consts';
import {
  Typography,
  Chip,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Dialog,
  DialogContent,
} from '@mui/material';
import React from 'react';
import {
  createVideoAdVersion,
  updateVideoAd,
} from '../../providers/dataProvider';
import { ChipInput } from '../ChipInput';
import { extractTakeFromName } from './utils';
import { Link } from 'react-router-dom';
import { CommentsDisplay } from './CommentsDisplay';
import { Role } from '../../resources/users';
import { AssignVideoSession } from './AssignVideoSession';
import { uploadToS3 } from '../../utils/assets';

const DEFAULT_NA = 'N/A';

interface Session {
  id: string;
  name: string;
}

const styles = makeStyles({
  mainCard: { paddingTop: '16px', maxWidth: '50%' },
  accordionContent: { maxWidth: '50%' },
  chipContainer: { width: '100%' },
  uploadAction: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '1%',
  },
  feedbackContent: { display: 'block', overflowWrap: 'anywhere' },
  uploadVideoAdVersionActionCard: {
    width: '100%',
    marginBottom: '1%',
    justifyContent: 'center',
    display: 'flex',
  },
  uploadVideoAdVersionActionButton: { width: '100%' },
});

const listVideoAdCreators = (
  versions?: {
    creators: {
      id: string;
    }[];
  }[],
) => {
  if (versions === null || versions === undefined) return [DEFAULT_NA];

  const creators = versions
    .flatMap(version => version.creators?.map((creator: any) => creator?.id))
    .filter(
      (value: any, index: any, self: any) =>
        value !== undefined && self.indexOf(value) === index,
    );

  if (creators.length < 1) return [DEFAULT_NA];
  return creators;
};

const createNewVideoAdVersion = async (
  file: File,
  videoAdId: string,
  takes: string[],
) => {
  try {
    // Create video ad version
    const createdVideoAd = await createVideoAdVersion(
      videoAdId,
      takes,
      file.size,
      file.type,
    );
    await uploadToS3(createdVideoAd.signData, file);
  } catch (err) {
    return false;
  }
};

const UploadVideoAdVersion: React.FC<any> = (props: any) => {
  const record = useRecordContext(props);
  const fileUrl = React.useMemo(
    () => window.URL.createObjectURL(record.rawFile),
    [record.rawFile],
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      wrap="nowrap"
      spacing={2}
    >
      <Grid item height="50%" width="50%">
        <video src={fileUrl} controls height="100%" width="100%" />
      </Grid>
      <Grid item height="50%" width="50%">
        <ChipInput
          source="takes"
          label="Takes"
          defaultValue={extractTakeFromName(record.rawFile.name)}
        />
      </Grid>
    </Grid>
  );
};

const UploadVideoAdVersionAction: React.FC<any> = (props: any) => {
  const refresh = useRefresh();
  const title = 'Upload new version';
  const [open, setOpen] = React.useState<boolean>(false); // Controls modal

  return (
    <div className={styles().uploadAction}>
      <Card className={styles().uploadVideoAdVersionActionCard}>
        <Button
          className={styles().uploadVideoAdVersionActionButton}
          label={title}
          onClick={() => setOpen(true)}
        >
          <Upload />
        </Button>
      </Card>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <SimpleForm
            toolbar={<SaveButton type="submit" />}
            onSubmit={async values => {
              await createNewVideoAdVersion(
                values.uploads.rawFile,
                values.id,
                values.takes || [],
              );
              refresh();
              setOpen(false);
            }}
          >
            <Typography variant="h5">{title}</Typography>
            <FileInput
              source="uploads"
              label=""
              id="localPath"
              accept="video/*"
              children={<UploadVideoAdVersion refresh={refresh} />}
              maxSize={1024 * 1024 * 100}
              helperText="Files above 100MB will be rejected"
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const VideoAdEdit: React.FC = (props: any) => {
  const record = useRecordContext(props);
  const notify = useNotify();

  const [expandedVersion, setExpandedVersion] = React.useState<number>(
    record?.versions?.length - 1,
  );
  const [recordSessions, setRecordSessions] = React.useState<Session[] | null>(
    null,
  );

  const feedbackContentClass = styles().feedbackContent;

  const userPermissions = usePermissions();

  return (
    <Edit
      {...props}
      actions={<UploadVideoAdVersionAction />}
      hasShow={false}
      mutationMode="pessimistic"
      mutationOptions={{
        onMutate: data =>
          updateVideoAd({
            ...data,
            data: {
              ...data.data,
              sessionIds:
                recordSessions?.map((session: any) => session.id) || undefined,
            },
          }),
        onSuccess: () => {
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        },
      }}
      redirect={'/videoads'}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        wrap="nowrap"
        spacing={2}
      >
        <Grid item>
          <Card>
            <FunctionField
              record={record}
              source="versions"
              render={(record: any) => {
                return record.versions
                  .sort((v1: any, v2: any) =>
                    v1.version > v2.version
                      ? -1
                      : v1.version === v2.version
                      ? 0
                      : 1,
                  )
                  .map((version: any) => {
                    return (
                      <Accordion
                        key={`${version.id}_${version.version}`}
                        expanded={
                          isNaN(expandedVersion)
                            ? record.latestVersion.version === version.version
                            : expandedVersion === version.version
                        }
                        onChange={() => {
                          window.scrollTo(0, 0);
                          setExpandedVersion(
                            expandedVersion === version.version
                              ? -1
                              : version.version,
                          );
                        }}
                      >
                        <AccordionSummary>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography>
                                Version {version.version + 1}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <DateField record={version} source="createdAt" />
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            wrap="nowrap"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid item maxWidth="50%">
                              <video
                                src={version.url}
                                height="100%"
                                width="100%"
                                controls
                              />
                            </Grid>
                            <Grid item container maxWidth="50%">
                              <Grid
                                item
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <Typography sx={{ display: 'inline-block' }}>
                                  Original Video Sessions:
                                </Typography>
                                {(record?.sessions || []).map(
                                  (session: any) => (
                                    <Link
                                      to={`/videosessions/${session.id.videoSessionId}/show`}
                                      style={{ marginLeft: '5px' }}
                                    >
                                      {session.videoSessionId}
                                    </Link>
                                  ),
                                )}
                              </Grid>
                              <Grid
                                container
                                alignContent="center"
                                justifyContent="space-between"
                              >
                                <Grid
                                  style={{
                                    padding: '5px',
                                  }}
                                >
                                  <Typography>Ad Version Comments</Typography>
                                  <CommentsDisplay
                                    adId={version.videoAdId}
                                    versionId={version.id}
                                    feedbackContentClass={feedbackContentClass}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  });
              }}
            />
          </Card>
        </Grid>
        <Grid item>
          <SimpleForm redirect="edit">
            <TextInput
              source="name"
              disabled={userPermissions.permissions !== Role.ADMIN}
            />
            <SelectArrayInput
              label="Platforms"
              source="platforms"
              choices={platformsChoices}
              optionText="name"
            />
            <Labeled label="Creators">
              <FunctionField
                className={styles().chipContainer}
                render={(record: any) => (
                  <div>
                    {listVideoAdCreators(record?.versions).map(
                      (creatorId: any) => (
                        <Chip
                          key={creatorId}
                          style={{ display: 'flex' }}
                          label={creatorId === -1 ? DEFAULT_NA : creatorId}
                        />
                      ),
                    )}
                  </div>
                )}
              />
            </Labeled>
            <Labeled label="Video ad ID">
              <TextField source="id" />
            </Labeled>

            <Labeled label="Video Sessions">
              <FunctionField
                className={styles().chipContainer}
                render={(videoAd: any) => (
                  <AssignVideoSession
                    record={videoAd}
                    sessions={recordSessions || videoAd.sessions}
                    setSessions={setRecordSessions}
                  />
                )}
              />
            </Labeled>
            <Labeled label="Revisions">
              <FunctionField
                render={(record: any) => `${record.versions.length - 1}/2`}
              />
            </Labeled>
          </SimpleForm>
        </Grid>
      </Grid>
    </Edit>
  );
};
