import React from 'react';
import {
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
} from 'react-admin';
import SetupIncentiveButton from './SetupIncentiveButton';
import PullIncentiveButton from './PullIncentiveButton';

// Icons
import CouponIcon from '@mui/icons-material/LocalActivity';
import TemplateIcon from '@mui/icons-material/Drafts';

export const IncentiveTypeChoices = [
  { id: 'AMAZON_GIFT_CARD', name: 'Amazon Gift Card' },
  { id: 'SHOPIFY', name: 'Shopify coupon' },
  { id: 'KLAVIYO', name: 'Klaviyo coupon' },
  { id: 'CUSTOM', name: 'Custom coupon' },
  { id: 'OTHER', name: 'Other' },
];


export const AmazonGiftCardSupportedLocales = [
  { id: 'US', name: 'United States - USD' },
  { id: 'DE', name: 'Germany - Euro' },
  { id: 'ES', name: 'Spain - Euro' },
  { id: 'UK', name: 'UK - GBP' },
];

export const IncentiveProvision = [
  { id: 'PARTICIPATION', name: 'Participation Reward' },
  { id: 'ONE_VETTED', name: 'Per Step' },
  { id: 'ALL_VETTED', name: 'Campaign Global' },
];

const AmazonGiftCardInputs: React.FC = () => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <NumberInput
      source="incentiveSetting.settings.value"
      label={'Value'}
      min={0}
      max={9999}
      isRequired
      validate={[required()]}
    />
    <SelectInput
      source="incentiveSetting.settings.locale"
      label="Currency"
      choices={AmazonGiftCardSupportedLocales}
      defaultValue="US"
    />
  </div>
);

const ShopifyInputs: React.FC<{
  formData: any;
  rest: any;
  showButtons: boolean;
}> = ({ formData, rest, showButtons }) => {
  const record = useRecordContext();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SelectInput
        source="incentiveSetting.settings.discountType"
        label="Discount type"
        choices={[
          { id: 'fixed_amount', name: 'Fixed amount' },
          { id: 'percentage', name: 'Percentage discount' },
        ]}
      />
      <NumberInput
        source="incentiveSetting.settings.discountValue"
        label={`Value (${
          formData.incentiveSetting?.settings?.discountType === 'percentage'
            ? '%'
            : '$'
        })`}
        min={0}
        max={
          formData.incentiveSetting?.settings?.discountType === 'percentage'
            ? 100
            : 9999
        }
      />
      <NumberInput
        source="incentiveSetting.settings.discountAmount"
        label="Amount of coupons"
        min={1}
        max={9999}
      />
      {showButtons ? (
        // @ts-ignore
        !record.incentiveSetting?.metadata?.discountJobStatus ? (
          <SetupIncentiveButton
            label="Create coupons"
            disabled={
              // @ts-ignore
              !(record.incentiveSetting?.type === 'SHOPIFY') &&
              // @ts-ignore
              !record.incentiveSetting?.metadata?.discountJobStatus
            }
            campaignId={record.id as string}
            {...rest}
          >
            <CouponIcon />
          </SetupIncentiveButton>
        ) : (
          // @ts-ignore
          <PullIncentiveButton record={record} />
        )
      ) : (
        <div />
      )}
    </div>
  );
};

const KlaviyoInputs: React.FC<{ showButtons: boolean; formData: any }> = ({
  showButtons,
  formData,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextInput
        source="incentiveSetting.settings.couponName"
        label="Coupon Name"
      />
      <TextInput
        source="incentiveSetting.settings.desc"
        label="Incentive Description"
      />
      {showButtons && (
        <SetupIncentiveButton
          label="Create template"
          campaignId={formData.id as string}
          {...formData}
        >
          <TemplateIcon />
        </SetupIncentiveButton>
      )}
    </div>
  );
};

const OtherInputs: React.FC = () => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <TextInput
      source="incentiveSetting.settings.desc"
      label="Incentive Description"
    />
  </div>
);

const IncentiveSetup: React.FC<{
  showButtons?: boolean;
  [key: string]: any;
}> = ({ showButtons = true, ...restProps }: any) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <RadioButtonGroupInput
        source="incentiveSetting.type"
        label="Type"
        choices={IncentiveTypeChoices}
        defaultValue={'AMAZON_GIFT_CARD'}
        {...restProps}
      />
      {
        <SelectInput
          source="incentiveSetting.incentiveProvision"
          label="Condition"
          choices={IncentiveProvision}
        />
      }
      <FormDataConsumer {...restProps}>
        {({ formData, ...rest }) => {
          return formData.incentiveSetting?.type === 'SHOPIFY' ? (
            <ShopifyInputs
              formData={formData}
              rest={rest}
              showButtons={showButtons}
            />
          ) : formData.incentiveSetting?.type === 'KLAVIYO' ? (
            <KlaviyoInputs formData={formData} showButtons={showButtons} />
          ) : formData.incentiveSetting?.type === 'AMAZON_GIFT_CARD' ? (
            <AmazonGiftCardInputs />
          ) : formData.incentiveSetting?.type === 'OTHER' ? (
            <OtherInputs />
          ) : (
            <div />
          );
        }}
      </FormDataConsumer>
    </div>
  );
};

export default IncentiveSetup;
