import React, { useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Button,
  FormDataConsumer,
  FormTab,
  FormTabProps,
  minValue,
  number,
  NumberInput,
  RadioButtonGroupInput,
  RaRecord,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  useRecordContext,
} from 'react-admin';
import { Typography, Tooltip, CircularProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import countries from '../../../assets/countries.json';
import languages from '../../../assets/languages.json';
import states from '../../../assets/states.json';
import { ChipInput } from '../../ChipInput';
import {
  downloadCustomersCSV,
  getCustomersSample,
} from '../../../providers/dataProvider';
import {
  cleanEmptyValues,
  EmptyTextualFieldProps,
} from '../../../utils/general';

const CastingTypeChoices = [
  { id: 'UPLOADED', name: 'Uploaded customers' },
  { id: 'SHOPIFY', name: 'Shopify customers' },
  { id: 'SUBSCRIBED_CREATORS', name: "Brand's subscribed creators" },
];

const ShopifyCastingFields: React.FC<{
  record: RaRecord;
  getSource: Function;
}> = ({ record, getSource }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <NumberInput
        source={getSource('minOrderCount')}
        label="Min. Order Count"
        validate={[required(), number(), minValue(1)]}
        isRequired
        defaultValue={1}
      />
      <NumberInput
        source={getSource('minAmountSpent')}
        label="Min. Amount Spent"
        validate={[required(), number(), minValue(0)]}
        isRequired
      />

      <SelectInput
        source={getSource('countryCode')}
        choices={countries}
        label="Country"
        resettable
        {...EmptyTextualFieldProps}
      />
      <SelectInput
        source={getSource('provinceCode')}
        choices={states}
        label="State"
        {...EmptyTextualFieldProps}
      />
      <SelectInput
        source={getSource('languageCode')}
        choices={languages}
        label="Language"
        {...EmptyTextualFieldProps}
      />
      <NumberInput
        source={getSource('daysSinceOrder')}
        label="Days Since Order"
      />
      <NumberInput
        source={getSource('daysToDiscard')}
        label="Days To Discard"
      />
      <BooleanInput
        source={getSource('onlyActive')}
        defaultValue={false}
        label="Active"
        format={v => !!v}
      />
      <BooleanInput
        source={getSource('onlySubscribedToEmail')}
        defaultValue={false}
        label="Email subscription"
        format={v => !!v}
      />
    </div>
  );
};

const UploadedCastingFields: React.FC<{
  record: RaRecord;
  getSource: Function;
}> = ({ record, getSource }) => (
  <ReferenceArrayInput
    source={getSource('uploadIds')}
    reference="customers/uploads"
    sort={{ field: 'createdAt', order: 'DESC' }}
    filter={{
      state: 'Success',
      brandId: record.brandId,
      hasLinkedCustomers: 'true',
    }}
  >
    <SelectArrayInput
      optionText={rec =>
        `Upload ${rec.fileName} with ${rec.linkedCustomers} linked customers (#${rec.id} created at ${rec.createdAt})`
      }
    />
  </ReferenceArrayInput>
);

const Casting: React.FC<FormTabProps & { target: 'campaignId' }> = ({
  target,
  ...rest
}) => {
  const record = useRecordContext();
  const [sampleIsLoading, setSampleIsLoading] = useState(false);
  const [sample, setSample] = useState<{
    total: number;
    count: number;
    tier: number;
  } | null>(null);

  return (
    <FormTab {...rest}>
      <div style={{ display: 'flex' }}>
        <NumberInput source="spamFilterDays" label="Spam filter (days)" />
        <Tooltip title="Skip customers who got any campaign in the last X days">
          <InfoIcon />
        </Tooltip>
      </div>
      <ArrayInput source="castingCriteria">
        <SimpleFormIterator>
          <NumberInput
            source="tier"
            label="Tier"
            isRequired
            validate={[
              required('Must set tier number'),
              number('Tier must be a number'),
              minValue(1),
            ]}
          />
          <Typography variant="h6">Batch Settings</Typography>
          <NumberInput
            source="batchSize"
            label="Size Limit"
            validate={[required(), number(), minValue(1)]}
            isRequired
            defaultValue={100}
          />
          <RadioButtonGroupInput
            source="castingType"
            label="Type"
            isRequired
            choices={CastingTypeChoices}
            defaultValue={
              record.brand?.integrations?.filter(
                (integ: { vendor: string }) => integ.vendor === 'SHOPIFY',
              ).length > 0
                ? 'SHOPIFY'
                : 'UPLOADED'
            }
          />
          <FormDataConsumer>
            {({ scopedFormData, formData, getSource, ...rest }) => {
              return (
                getSource && (
                  <div>
                    {scopedFormData?.castingType === 'SHOPIFY' && (
                      <ShopifyCastingFields
                        getSource={getSource}
                        record={record}
                      />
                    )}
                    {scopedFormData?.castingType === 'UPLOADED' && (
                      <UploadedCastingFields
                        getSource={getSource}
                        record={record}
                      />
                    )}
                    <ChipInput
                      source={getSource('excludeKeywords')}
                      label="Exclude Keywords"
                    />
                    <BooleanInput
                      source={getSource('excludePreviousBatches')}
                      defaultValue={true}
                      label="Exclude Previous Batches"
                    />
                    <Button
                      onClick={() =>
                        downloadCustomersCSV({
                          campaignId: record.id,
                          ...cleanEmptyValues(scopedFormData),
                        })
                      }
                      label="Download"
                      variant="contained"
                    />
                    <div
                      style={{
                        display: 'flex',
                        margin: '15px 0',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        label={sampleIsLoading ? 'Loading...' : 'Sample'}
                        style={{ marginRight: '10px' }}
                        endIcon={
                          sampleIsLoading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : null
                        }
                        onClick={async () => {
                          try {
                            setSampleIsLoading(true);
                            const res = await getCustomersSample({
                              campaignId: record.id,
                              ...scopedFormData,
                            });
                            setSampleIsLoading(false);
                            setSample({
                              ...res.json,
                              tier: scopedFormData.tier,
                            });
                          } catch (err) {
                            setSampleIsLoading(false);
                            console.error(err);
                          }
                        }}
                        variant="contained"
                      />
                      {sample && sample.tier === scopedFormData?.tier && (
                        <Typography variant="body1">
                          {sample.count} / {sample.total} possible matching
                          customers
                        </Typography>
                      )}
                    </div>
                  </div>
                )
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  );
};

export default Casting;
