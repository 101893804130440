import { Typography } from '@mui/material';
import { NoOfPeople } from '../../consts';
import { SingleSelect } from '../CustomInputs/Select';

interface MetadataDisplayProps {
  campaignId: any;
  metadata?: any;
  onUpdateField: (field: string, value?: string | string[]) => void;
}
const MetadataDisplay = ({
  campaignId,
  metadata = {},
  onUpdateField,
}: MetadataDisplayProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography style={{ textDecorationLine: 'underline' }} variant="h6">
          Metadata
        </Typography>
      </div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '20%',
            marginRight: '5%',
          }}
        >
          <SingleSelect
            title="# Of People"
            labelId="noOfPeople"
            onChange={(e: any) => onUpdateField('noOfPeople', e.target.value)}
            options={NoOfPeople}
            value={metadata?.noOfPeople}
          />
        </div>
      </div>
    </div>
  );
};
export default MetadataDisplay;
