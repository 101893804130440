import React from 'react';
import { ImageList } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RaRecord, useListContext } from 'react-admin';
import GalleryItem, { ItemType } from '../GalleryItem';
import { BrandVettingMethod } from '../../types';

export const galleryStyles = makeStyles({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
  videoContainer: {
    position: 'relative',
  },
  videoContainerSmall: {
    height: '450px',
    width: '400px',
  },
  videoContainerLarge: {
    height: '600px',
  },
  videoContainerXS: {
    height: '150px',
    width: '150px',
  },
  checkbox: {
    width: '1.5rem',
    height: '1.5rem',
  },
  video: {
    backgroundColor: '#000',
  },
  actionsContainer: {
    position: 'absolute',
    zIndex: 10000,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'scale-down',
    border: '1px solid black',
  },
});

interface MediaGalleryProps {
  itemType: ItemType;
  Footer?: React.ComponentType<any>;
  filter?: (record: RaRecord) => boolean;
  takeHeaderControls?: boolean;
}

const MediaGallery: React.FC<MediaGalleryProps> = ({
  itemType,
  Footer,
  filter = () => true,
  takeHeaderControls = true,
}) => {
  const { data, onToggleItem, selectedIds, isLoading, refetch } =
    useListContext();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ImageList cols={4} gap={8} component="ul">
      {data.filter(filter).map(record => (
        <GalleryItem
          key={record.id}
          record={record}
          Footer={Footer}
          itemType={itemType}
          isSelected={selectedIds.includes(record.id)}
          onToggleItem={onToggleItem}
          size={'lg'}
          takeHeaderControls={takeHeaderControls}
          refreshTakes={refetch}
          disableTakeVetting={
            record.campaign?.brand?.vettingMethod === BrandVettingMethod.BRAND
          }
        />
      ))}
    </ImageList>
  );
};

export default MediaGallery;
